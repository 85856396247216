<script>
import PreSurveyMobileLayout from '@/components/forms/pre-survey/mobile/PreSurveyForm'
import PreSurveyDesktopLayout from '@/components/forms/pre-survey/web/PreSurveyForm'
import Vue from 'vue'

//TODO: change this to swap between the Mobile and Web version of the dynamic form.
// Just putting a note here since the form needs to be loaded 1 level above and passed through here
export default {
    functional: true,
    name: "PreSurveySwitchLayout",
    render(createElement, context) {
        // can be removed AFTER adding in code to pass through the list of questions
        if (Vue.$device.mobile) {
            // TODO: add back mobile site when it's ready
            return createElement(PreSurveyMobileLayout, context.data, context.children)
            // return createElement(ComingSoonLayout, context.data, context.children)
        }
        return createElement(PreSurveyDesktopLayout, context.data, context.children)
    }
}
</script>
