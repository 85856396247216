<template>
    <div class="container m-auto my-3" :class="{'p-0 m-0': isExternal}">
        <PreSurveySwitchLayout :shortName="shortName" />
    </div>
</template>

<script>
import PreSurveySwitchLayout from '@/components/forms/pre-survey/PreSurveySwitchLayout'
import {createNamespacedHelpers} from 'vuex-composition-helpers'
import {onMounted, ref} from '@vue/composition-api'

const { useActions } = createNamespacedHelpers('')


export default {
    name: 'PreSurveyView',
    components: {PreSurveySwitchLayout},
    setup(_, { root }) {
        const { setFormTitle } = useActions(['setFormTitle'])
        const title = ref('')
        const route = root.$route
        const params = route?.params
        const isExternal = ref(params?.ext)
        const shortName = ref(params?.shortName)

        //GET_FORM_SET_ID_BY_SHORT_NAME
        onMounted(() => {
            setFormTitle(title.value)
        })

        return {
            title,
            isExternal,
            shortName
        }
    }
}
</script>
